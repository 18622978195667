import phone from 'phone'
import { useEffect, useState } from 'react'
import { Container, ErrorMsg, Input } from './TextInput.styles'

export const AutoCompleteValues = [
  'on',
  'off',
  'address-line1',
  'address-line2',
  'address-line3',
  'address-level1',
  'address-level2',
  'address-level3',
  'address-level4',
  'street-address',
  'country',
  'country-name',
  'postal-code',
  'name',
  'additional-name',
  'family-name',
  'give-name',
  'honoric-prefix',
  'honoric-suffix',
  'nickname',
  'organization-title',
  'username',
  'new-password',
  'current-password',
  'bday',
  'bday-day',
  'bday-month',
  'bday-year',
  'sex',
  'one-time-code',
  'organization',
  'cc-name',
  'cc-given-name',
  'cc-additional-name',
  'cc-family-name',
  'cc-number',
  'cc-exp',
  'cc-exp-month',
  'cc-exp-year',
  'cc-csc',
  'cc-type',
  'transaction-currency',
  'transaction-amount',
  'language',
  'url',
  'email',
  'photo',
  'tel',
  'tel-country-code',
  'tel-national',
  'tel-area-code',
  'tel-local',
  'tel-local-prefix',
  'tel-local-suffix',
  'tel-extension',
  'impp',
] as const

export type AutoComplete = (typeof AutoCompleteValues)[number]

interface TextInputProps {
  type: 'text' | 'url' | 'email' | 'phone'
  defaultValue?: string
  name?: string
  readOnly?: boolean
  placeholder?: string
  value: string
  onChange: (props: any) => any
  disabled?: boolean
  bgColor?: string
  padding?: string
  borderRadius?: string
  border?: string
  boxShadow?: string
  margin?: string
  color?: string
  colorFocus?: string
  bgColorFocus?: string
  borderFocus?: string
  bgColorDisabled?: string
  borderDisabled?: string
  colorDisabled?: string
  fontSize?: string
  fontWeight?: string
  outline?: string
  errorColor?: string
  onError?: (boolean) => void
  required?: boolean
  requiredError?: string
  requiredErrorLabel?: string
  phoneErrorLabel?: string
  emailErrorLabel?: string
  customErrorLabel?: string
  phoneError?: string
  emailError?: string
  customError?: string
  autoComplete?: AutoComplete
}

export const TextInput = ({
  type,
  defaultValue,
  name,
  readOnly,
  placeholder,
  value,
  onChange,
  disabled,
  bgColor,
  padding,
  borderRadius,
  border,
  boxShadow,
  margin,
  color,
  colorFocus,
  bgColorFocus,
  borderFocus,
  bgColorDisabled,
  borderDisabled,
  colorDisabled,
  fontSize,
  fontWeight,
  outline,
  errorColor,
  onError,
  required,
  requiredErrorLabel,
  phoneErrorLabel,
  emailErrorLabel,
  customErrorLabel,
  autoComplete,
}: TextInputProps) => {
  const [errorLabel, setErrorLabel] = useState('')
  const [focused, setFocused] = useState<boolean>(false)

  function handleValidation(value: string) {
    setErrorLabel('')
    onError && onError(false)

    const reEmail = new RegExp('^[a-zA-Z0-9_.]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$')
    // const rePhone =
    //   /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    const rePhone = phone(value)

    if (value && value.length) {
      if (type === 'email' && !reEmail.test(value)) {
        setErrorLabel(emailErrorLabel ? emailErrorLabel : 'Please enter a valid e-mail address')
        onError && onError(true)
      }

      if (type === 'phone' && !rePhone.isValid) {
        setErrorLabel(phoneErrorLabel ? phoneErrorLabel : 'Please enter a valid phone number with country code prefix')
        onError && onError(true)
      }

      if (type === 'text' && customErrorLabel) {
        setErrorLabel(customErrorLabel)
        onError && onError(true)
      }
    } else {
      if (required && focused) {
        setErrorLabel(requiredErrorLabel ? requiredErrorLabel : 'Please fill the required field')
        onError && onError(true)
      }
    }
  }

  useEffect(() => {
    handleOnChange(value)
  }, [value])

  function handleOnChange(value: string) {
    handleValidation(value)
    onChange && onChange(value)
  }

  return (
    <Container>
      <Input
        type={type}
        defaultValue={defaultValue}
        name={name}
        required={!!required}
        readOnly={!!readOnly}
        placeholder={placeholder}
        value={value}
        onFocus={() => setFocused(true)}
        onChange={(e) => handleOnChange(e.target.value)}
        disabled={disabled}
        bgColor={bgColor}
        padding={padding}
        borderRadius={borderRadius}
        border={border}
        boxShadow={boxShadow}
        margin={margin}
        color={color}
        colorFocus={colorFocus}
        bgColorFocus={bgColorFocus}
        borderFocus={borderFocus}
        bgColorDisabled={bgColorDisabled}
        borderDisabled={borderDisabled}
        colorDisabled={colorDisabled}
        fontSize={fontSize}
        fontWeight={fontWeight}
        outline={outline}
        onBlur={(e) => handleValidation(e.target.value)}
        autoComplete={autoComplete || 'off'}
      />
      <ErrorMsg color={errorColor}>{errorLabel}</ErrorMsg>
    </Container>
  )
}
