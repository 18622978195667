import { FC, PropsWithChildren } from 'react'
import { StyledStatus } from './Status.styles'

type Props = {
    status: string
  }

export const Status: FC<PropsWithChildren<Props>> = (props) => {
    return (
        <StyledStatus
          status={props.status}
        >
          {props.children}
        </StyledStatus>
      )
}