import { API_URL } from '@/constants/envs'
import axios from 'axios'

export const axiosInstanceUnauthorized = axios.create({
  baseURL: API_URL,
  withCredentials: false,
})

export const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
})
