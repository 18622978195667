import { FC, PropsWithChildren } from 'react'
import { Link } from '@components/generic/Link/Link'
import { StyledDataValue, Label } from './DataValue.styles'
import { useTheme } from 'styled-components'

type Props = {
  label?: string
  data: string
  fileName: string
}

export const DataJsonDownload: FC<PropsWithChildren<Props>> = (props) => {
  const theme = useTheme()

  return (
    <StyledDataValue>
      {props.label && <Label>{props.label}:</Label>}
      <Link
        href={`data:text/json;charset=utf-8,${encodeURIComponent(props.data)}`}
        download={props.fileName}
        {...theme.buttons.downloadButton}
        width="fit-content"
      >
        Download
      </Link>
    </StyledDataValue>
  )
}
