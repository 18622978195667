import styled from 'styled-components'
import { Animatable } from '@styles/generic.styles'

export const StyledGoBackIcon = styled.span`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.secondary};
  background-color: transparent;
  border-radius: 26px;
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.secondary4};
  }

  svg {
    width: 20px;
    height: 20px;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      width: 15px;
      height: 15px;
    }
  }

  ${Animatable}
`
