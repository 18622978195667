import { observer } from 'mobx-react'
import { FC, useContext, useEffect, useState } from 'react'
import { Button } from '@components/generic/Button/Button'
import { LoadingSpinner } from '@components/generic/LoadingSpinner/LoadingSpinner'
import { Redirect } from 'wouter'
import { StoreContext } from '@components/App'
import { Logo, Spacer, Subtitle, TextInput, Title } from '@components/generic'
import {
  Aligner,
  Container,
  LeftSide,
  PositionedIllustration,
  PositionedLogo,
  RightSide,
  TransparentContainer,
  WarningMessage,
} from './LoginPage.styles'
import { LoginIllustration } from './LoginIllustration/LoginIllustration'
import { useTheme } from 'styled-components'

export const LoginPage: FC = observer(() => {
  const store = useContext(StoreContext)
  const { loginPage: trans } = store.TranslationsState.translations
  const theme = useTheme()

  const { loginPageSubtitle } = store.AppState
  const { isLogged, shouldLogout, currentUserId } = store.Auth

  const [redirecting, setRedirecting] = useState(false)
  const [unauthMessage, setUnauthMessage] = useState('')
  const [email, setEmail] = useState('')
  const [domain, setDomain] = useState('')
  const [authDisabled, setAuthDisabled] = useState(true)

  const handleAuth = () => {
    setRedirecting(true)
    const apiUrl = process.env.WEB_API_URL
    store.Auth.setShouldLogout(false)
    window.location.href = `${apiUrl}/redirect-to-auth?domain=${domain}`
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  useEffect(() => {
    const emailSplit = email.split('@')
    if (emailSplit.length > 1) {
      setDomain(emailSplit[1])
    }
    validateEmail(email) && setAuthDisabled(false)
  }, [email])

  useEffect(() => {
    setUnauthMessage(location.pathname.split('/')[2])
  }, [])

  return (
    <Container>
      <LeftSide>
        <PositionedLogo>
          <Logo></Logo>
        </PositionedLogo>
        <PositionedIllustration>
          <LoginIllustration></LoginIllustration>
        </PositionedIllustration>
      </LeftSide>
      <RightSide>
        <Aligner>
          {!redirecting ? (
            <TransparentContainer>
              {unauthMessage && unauthMessage.length && <WarningMessage>{unauthMessage}</WarningMessage>}
              <Title>{trans.welcomeBack}</Title>
              <Spacer size={14}></Spacer>
              <Subtitle>{loginPageSubtitle}</Subtitle>
              <Spacer size={32} mobileSize={25}></Spacer>
              <form onSubmit={handleAuth}>
                <TextInput
                  type="email"
                  name="email"
                  required
                  value={email}
                  onChange={(value: string) => setEmail(value)}
                  placeholder={trans.enterEmail}
                />
                <Spacer size={50} mobileSize={25}></Spacer>
                <Button {...theme.buttons.commonButton} onClick={handleAuth} disabled={authDisabled} fontSize="1.56rem">
                  {trans.logIn}
                </Button>
                <Spacer size={25}></Spacer>
              </form>
            </TransparentContainer>
          ) : (
            <TransparentContainer>
              <Title>{trans.redirecting}...</Title>
              <Spacer size={14}></Spacer>
              <Subtitle>{trans.pleaseWaitRedirectionToMicrosoft}</Subtitle>
              <Spacer size={50} mobileSize={25}></Spacer>
              <LoadingSpinner width="60px" {...theme.loadingSpinner} />
            </TransparentContainer>
          )}
        </Aligner>
      </RightSide>

      {isLogged && currentUserId.length && !shouldLogout ? <Redirect to={'/transactions'} /> : <></>}
    </Container>
  )
})
