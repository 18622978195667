import { FC, PropsWithChildren } from 'react'
import { StyledCheckIcon } from './CheckIcon.styles'
import { useTheme } from 'styled-components'

export const CheckIcon: FC<PropsWithChildren> = () => {
  const theme = useTheme()

  return (
    <>
    <StyledCheckIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M8.79496 15.8749L4.62496 11.7049L3.20496 13.1149L8.79496 18.7049L20.795 6.70492L19.385 5.29492L8.79496 15.8749Z" fill={theme.createTransaction.resultsSuccessStatusColor} />
      </svg>
    </StyledCheckIcon>
    </>
  )
}
