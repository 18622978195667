import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { App } from '@components/App'
import { hideStyledComponentsWarns } from '@methods/handleWarns'

const container = document.getElementById('root')
const root = createRoot(container!)

hideStyledComponentsWarns()

root.render(
  <StrictMode>
    <App />
  </StrictMode>
)
