import styled from 'styled-components'
import { Animatable } from '@styles/generic.styles'

export const StyledTextInput = styled.input`
  width: 100%;
  min-width: 114px;
  height: 35px;
  font-family: ${({ theme }) => theme.fonts.familyVariant};
  padding: 6px 6px 6px 12px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.quinary};
  background-color: inherit;
  color: ${({ theme }) => theme.colors.secondary2};

  &:focus {
    border-color: ${({ theme }) => theme.colors.secondary2};
  }

  &:disabled {
    border-color: ${({ theme }) => theme.colors.quinary};
    background-color: ${({ theme }) => theme.colors.quinary};
    color: ${({ theme }) => theme.colors.septenary2};
  }

  ${Animatable}
`
