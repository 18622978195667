import { FC, PropsWithChildren, useCallback, useContext } from 'react'
import { SelectInput } from '@components/generic/SelectInput/SelectInput'
import { observer } from 'mobx-react'
import { useLocation } from 'wouter'
import { Menu as DropdownMenu, MenuItem as DropdownMenuItem } from '@szhsin/react-menu'
import { Language } from '@store/Translations.store'
import { axiosInstance } from '@/axios/AxiosInstance'
import { StoreContext } from '@components/App'
import useCheckMobile from '@hooks/useCheckMobile'
import {
  StyledMenu,
  MenuNav,
  MenuToolbox,
  Overlay,
  PositionedHamburger,
  TenantSelector,
  MenuDropdownButton,
  MenuDropdownWrapper,
  MenuToolboxMobile,
  MobileTenantSelector,
} from './Menu.styles'
import { StyledLogo } from './Menu.styles'
import { MenuItem } from './MenuItem'
import { ThemeSwitcher } from '@/components'
import { MenuItemIcon } from './Icons/MenuItemIcon'
import { MenuItemName } from './MenuItemName'
import { Logo } from '@components/generic'
import { useLocalStorage } from 'usehooks-ts'
import { SelectOption } from '@constants/SelectOption'
import { SettingsIcon as DropdownMenuIcon, SettingsIcon } from '@components/Icon/components/SettingsIcon'
import { CreateTransactionIcon } from '@components/Icon/components/CreateTransactionIcon'
import { TenantDropdownWrapper } from '@/styles/generic.styles'
import { useTheme } from 'styled-components'
import { toSelectOptions, tenantsSort } from '@helpers/selectHelpers'
import { CloseIcon } from '../Icon/CloseIcon/CloseIcon'
import { HamburgerIcon } from '../Icon/HamburgerIcon/HamburgerIcon'
import { LogoutIcon } from '../Icon/LogoutIcon/LogoutIcon'
import { LogsIcon } from '../Icon/LogsIcon/LogsIcon'
import { ThemeIcon } from '../Icon/ThemeIcon/ThemeIcon'
import { useDarkMode } from '@hooks/useDarkmode'

export const Menu: FC<PropsWithChildren> = observer(() => {
  const [menuOpened, setMenuOpened] = useLocalStorage('menuOpened', true)
  const { toggle: toggleDarkMode } = useDarkMode()

  const store = useContext(StoreContext)
  const { menu: trans } = store.TranslationsState.translations
  const { tenantsWithWriteRights, selectedTenant, tenants } = store.AppState
  const theme = useTheme()
  const isMobileView = useCheckMobile()

  const tenantsList = toSelectOptions(tenants.sort(tenantsSort))
  const selectedTenantIndex = tenantsList.findIndex((item) => item.value === selectedTenant)

  const logout = useCallback(async () => {
    if (isMobileView) setMenuOpened(false)

    store.Auth.setIsLogged(false)
    store.Auth.setShouldLogout(true)
    store.Auth.setCurrentUserId('')

    await axiosInstance.delete(`/users/me/sessions/current`, {
      withCredentials: true,
    })

    setLocation('/login')
  }, [])

  const onLanguageChange = (e: SelectOption) => store.TranslationsState.setTranslations(e.value as Language)

  const goToSettings = useCallback(() => {
    if (isMobileView) setMenuOpened(false)

    setLocation('/settings')
  }, [])

  const goToTransactions = useCallback(() => {
    if (isMobileView) setMenuOpened(false)

    setLocation('/transactions')
  }, [])

  const goToOnboarding = useCallback(() => {
    if (isMobileView) setMenuOpened(false)

    setLocation('/create-transaction')
  }, [])

  const [location, setLocation] = useLocation()

  const renderTenantSwitcher = (isMobile?: boolean) => {
    if (!isMobile) {
      return (
        <TenantSelector>
          <span>clientId:</span>
          <TenantDropdownWrapper>
            <SelectInput
              options={tenantsList}
              value={tenantsList[selectedTenantIndex]}
              onChange={(e: SelectOption) => {
                void store.AppState.setSelectedTenant(e.value)
                if (location !== '/transactions') {
                  setLocation('/transactions')
                }
              }}
              placeholder={trans.selectTenant}
              {...theme.selectInput}
            />
          </TenantDropdownWrapper>
        </TenantSelector>
      )
    } else if (tenants && tenants.length && isMobile) {
      return (
        <MobileTenantSelector>
          <TenantDropdownWrapper>
            <SelectInput
              options={tenantsList}
              value={tenantsList[selectedTenantIndex]}
              onChange={(e: SelectOption) => {
                void store.AppState.setSelectedTenant(e.value)
              }}
              placeholder={trans.selectTenant}
              {...theme.selectInput}
            />
          </TenantDropdownWrapper>
        </MobileTenantSelector>
      )
    } else {
      return <></>
    }
  }

  const isSelectedTenantWithWriteRights = tenantsWithWriteRights.includes(selectedTenant)
  //const isAnyAvailableTenantWithWriteRights = tenantsWithWriteRights.length > 0

  return (
    <>
      <PositionedHamburger>
        {!menuOpened ? (
          <HamburgerIcon onClick={() => setMenuOpened(!menuOpened)}></HamburgerIcon>
        ) : (
          <CloseIcon onClick={() => setMenuOpened(!menuOpened)}></CloseIcon>
        )}
      </PositionedHamburger>

      <StyledMenu open={menuOpened}>
        <MenuNav>
          <StyledLogo>
            <Logo />
          </StyledLogo>

          {renderTenantSwitcher(true)}

          <MenuItem onClick={goToTransactions} active={!!location.match('transactions')}>
            <MenuItemIcon>
              <LogsIcon />
            </MenuItemIcon>
            <MenuItemName>{trans.transactions}</MenuItemName>
          </MenuItem>

          {isSelectedTenantWithWriteRights ? (
            <>
              <MenuItem onClick={goToOnboarding} active={!!location.match('create-transaction')}>
                <MenuItemIcon>
                  <CreateTransactionIcon />
                </MenuItemIcon>
                <MenuItemName>{trans.createTransaction}</MenuItemName>
              </MenuItem>
            </>
          ) : (
            <></>
          )}
        </MenuNav>

        <MenuToolbox>
          {renderTenantSwitcher()}

          <MenuDropdownWrapper>
            <DropdownMenu
              menuButton={
                <MenuDropdownButton>
                  <DropdownMenuIcon />
                </MenuDropdownButton>
              }
            >
              <DropdownMenuItem
                onClick={(e) => {
                  e.stopPropagation = true
                  e.keepOpen = true
                }}
              >
                <ThemeSwitcher onClick={toggleDarkMode}>
                  <ThemeIcon />
                  <MenuItemName>{trans.theme}</MenuItemName>
                </ThemeSwitcher>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={goToSettings}>
                <MenuItemIcon>
                  <SettingsIcon />
                </MenuItemIcon>
                <MenuItemName>{trans.settings}</MenuItemName>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={logout}>
                <MenuItemIcon>
                  <LogoutIcon />
                </MenuItemIcon>
                <MenuItemName>{trans.logOut}</MenuItemName>
              </DropdownMenuItem>
            </DropdownMenu>
          </MenuDropdownWrapper>
        </MenuToolbox>

        <MenuToolboxMobile>
          <MenuItem>
            <ThemeSwitcher onClick={toggleDarkMode}>
              <ThemeIcon />
              <MenuItemName>{trans.theme}</MenuItemName>
            </ThemeSwitcher>
          </MenuItem>
          <MenuItem onClick={goToSettings} active={!!location.match('settings')}>
            <MenuItemIcon>
              <SettingsIcon />
            </MenuItemIcon>
            <MenuItemName>{trans.settings}</MenuItemName>
          </MenuItem>
          <MenuItem onClick={logout}>
            <MenuItemIcon>
              <LogoutIcon />
            </MenuItemIcon>
            <MenuItemName>{trans.logOut}</MenuItemName>
          </MenuItem>
        </MenuToolboxMobile>
      </StyledMenu>
      <Overlay visible={menuOpened} />
    </>
  )
})
