import styled, { css } from 'styled-components'

export const PillContainer = styled.div<{ wrap: string }>`
  display: flex;
  gap: 8px;
  flex-wrap: ${(props) => props.wrap};
  position: relative;
  overflow: visible !important;
  width: 100%;
`

export const Pill = styled.div`
  font-size: 0.9em;
  width: fit-content;
  max-width: 100%;
  min-height: 32px;
  display: flex;

  &.hidden {
    display: none;
  }
`

export const PillDots = styled.div`
  color: ${({ theme }) => theme.colors.secondary4};
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 6px 10px;
  border-radius: 16px;
  font-size: 0.9em;
  cursor: pointer;
`

export const PillsHover = styled.div<{ position: 'top' | 'bottom' }>`
  position: absolute;
  z-index: 1;
  left: -5px;
  padding: 5px;
  background-color: ${({ theme }) => theme.colors.tertiary2};
  border-radius: 16px;
  min-width: 100%;
  width: auto;

  ${({ position }) =>
    position === 'bottom'
      ? css`
          top: 32px;
        `
      : css`
          bottom: 32px;
        `}

  & > div:not(:first-of-type) {
    margin-top: 5px;
  }
`
