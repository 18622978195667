import styled, { css } from 'styled-components'

export const StyledBareButton = styled.span<{ disabled?: boolean }>`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.secondary2};
  text-decoration-line: underline;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`
