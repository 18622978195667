import { FC, MouseEventHandler, PropsWithChildren } from 'react'
import { StyledDeletePiiDataButton } from './DeletePiiDataButton.styles'

type Props = {
  onClick: MouseEventHandler
  title: string
  disabled?: boolean
}

export const DeletePiiDataButton: FC<PropsWithChildren<Props>> = (props) => {
  return (
    <StyledDeletePiiDataButton
      disabled={!!props.disabled}
      onClick={!props.disabled ? props.onClick : undefined}
      title={props.title}
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        fill={!props.disabled ? 'currentColor' : '#fff'}
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <path
              d="M17.5,24h-11C5.122,24,4,22.878,4,21.5v-14C4,7.224,4.224,7,4.5,7h15C19.776,7,20,7.224,20,7.5v14
				C20,22.878,18.878,24,17.5,24z M5,8v13.5C5,22.327,5.673,23,6.5,23h11c0.827,0,1.5-0.673,1.5-1.5V8H5z"
            />
          </g>
          <g>
            <path
              d="M20.5,6h-17C3.224,6,3,5.776,3,5.5v-2C3,3.224,3.224,3,3.5,3h0.55C4.283,1.86,5.292,1,6.5,1h11
				c1.208,0,2.217,0.86,2.45,2h0.55C20.776,3,21,3.224,21,3.5v2C21,5.776,20.776,6,20.5,6z M4,5h16V4h-0.5C19.224,4,19,3.776,19,3.5
				C19,2.673,18.327,2,17.5,2h-11C5.673,2,5,2.673,5,3.5C5,3.776,4.776,4,4.5,4H4V5z"
            />
          </g>
          <g>
            <path d="M7.5,21C7.224,21,7,20.776,7,20.5v-10C7,10.224,7.224,10,7.5,10S8,10.224,8,10.5v10C8,20.776,7.776,21,7.5,21z" />
          </g>
          <g>
            <path d="M12,21c-0.276,0-0.5-0.224-0.5-0.5v-10c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v10C12.5,20.776,12.276,21,12,21z" />
          </g>
          <g>
            <path d="M16.5,21c-0.276,0-0.5-0.224-0.5-0.5v-10c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v10C17,20.776,16.776,21,16.5,21z" />
          </g>
        </g>
      </svg>
    </StyledDeletePiiDataButton>
  )
}
