import styled from 'styled-components'
import { Animatable } from './generic.styles'

export const DateTimePickerStyled = styled.div`
  .react-datetime-picker {
    position: relative;
    font-family: ${({ theme }) => theme.fonts.familyVariant};
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      width: 100%;
    }
  }

  .react-datetime-picker,
  .react-datetime-picker *,
  .react-datetime-picker *:before,
  .react-datetime-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .react-datetime-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: 1px solid ${({ theme }) => theme.colors.quinary};
    color: ${({ theme }) => theme.colors.secondary2};
    padding: 3px 6px;
    border-radius: ${({ theme }) => theme.calendar.borderRadius};

    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.secondary2};
    }

    ${Animatable}
  }

  .react-datetime-picker--disabled {
    background-color: ${({ theme }) => theme.colors.quinary};
    color: ${({ theme }) => theme.colors.septenary2};
    border-radius: 6px;
  }

  .react-datetime-picker--disabled .react-datetime-picker__wrapper {
    color: ${({ theme }) => theme.colors.septenary2};

    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.quinary};
    }
  }

  .react-datetime-picker__inputGroup {
    min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2);
    flex-grow: 1;
    padding: 0 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .react-datetime-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
  }

  .react-datetime-picker__inputGroup__divider,
  .react-datetime-picker__inputGroup__leadingZero {
    display: inline-block;
  }

  .react-datetime-picker__inputGroup__input {
    min-width: 0.54em;
    height: calc(100% - 2px);
    position: relative;
    padding: 1px;
    border: 0;
    background: none;
    color: currentColor;
    font: inherit;
    box-sizing: content-box;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  .react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  .react-datetime-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }

  .react-datetime-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }

  .react-datetime-picker__inputGroup__amPm {
    font: inherit;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;
  }

  .react-datetime-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
  }

  .react-datetime-picker__button:enabled {
    cursor: pointer;
  }

  .react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
  .react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
    stroke: ${({ theme }) => theme.colors.secondary2};
  }

  .react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
    stroke: ${({ theme }) => theme.colors.secondary2};
  }

  .react-datetime-picker__button svg {
    display: inherit;
    stroke: ${({ theme }) => theme.colors.secondary2};
  }

  .react-datetime-picker__calendar,
  .react-datetime-picker__clock {
    z-index: 1;
  }

  .react-datetime-picker__calendar--closed,
  .react-datetime-picker__clock--closed {
    display: none;
  }

  .react-datetime-picker__calendar {
    width: 350px;
    max-width: 100vw;
  }

  .react-datetime-picker__calendar .react-calendar {
    border-width: thin;
  }

  .react-datetime-picker__clock {
    width: 200px;
    height: 200px;
    max-width: 100vw;
    padding: 25px;
    background-color: white;
    border: thin solid #a0a096;
  }

  .react-calendar {
    width: 350px;
    max-width: 100%;
    background: ${({ theme }) => theme.calendar.backgroundColor};
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    border-radius: ${({ theme }) => theme.calendar.borderRadius};
    border: 1px solid ${({ theme }) => theme.calendar.borderColor};
    padding: 6px;
  }

  .react-calendar--doubleView {
    width: 700px;
  }

  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }

  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }

  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }

  .react-calendar button:enabled:hover {
    cursor: pointer;
  }

  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
  }

  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    color: ${({ theme }) => theme.calendar.dayColor};
  }

  .react-calendar__navigation button:disabled {
    background-color: ${({ theme }) => theme.calendar.navigation.button.disabled.backgroundColor};
  }

  .react-calendar__navigation button.react-calendar__navigation__arrow {
    font-size: 1.2rem;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: ${({ theme }) => theme.calendar.navigation.button.hover.backgroundColor};
    border-radius: ${({ theme }) => theme.calendar.navigation.button.borderRadius};
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }

  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }

  .react-calendar__month-view__days__day {
    color: ${({ theme }) => theme.calendar.dayColor};
  }

  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }

  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: ${({ theme }) => theme.calendar.tile.padding};
  }

  .react-calendar__tile {
    max-width: 100%;
    padding: ${({ theme }) => theme.calendar.tile.padding};
    background: none;
    text-align: center;
    line-height: 16px;
  }

  .react-calendar__tile:disabled {
    background-color: ${({ theme }) => theme.calendar.tile.disabled.backgroundColor};
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: ${({ theme }) => theme.calendar.tile.hover.backgroundColor};
    border-radius: ${({ theme }) => theme.calendar.tile.borderRadius};
  }

  .react-calendar__tile--now {
    background: ${({ theme }) => theme.calendar.tile.now.backgroundColor};
    color: ${({ theme }) => theme.calendar.tile.now.color};
    border-radius: ${({ theme }) => theme.calendar.tile.borderRadius};
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: ${({ theme }) => theme.calendar.tile.now.hover.backgroundColor};
  }

  .react-calendar__tile--hasActive {
    background: ${({ theme }) => theme.calendar.tile.hasActive.backgroundColor};
    color: ${({ theme }) => theme.calendar.tile.hasActive.color};
    border-radius: ${({ theme }) => theme.calendar.tile.borderRadius};
  }

  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: ${({ theme }) => theme.calendar.tile.hasActive.hover.backgroundColor};
  }

  .react-calendar__tile--active {
    background: ${({ theme }) => theme.calendar.tile.active.backgroundColor};
    color: ${({ theme }) => theme.calendar.tile.active.color};
    border-radius: ${({ theme }) => theme.calendar.tile.borderRadius};
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: ${({ theme }) => theme.calendar.tile.active.hover.backgroundColor};
  }

  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
`
