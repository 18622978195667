/* eslint-disable */
import { linter } from '@codemirror/lint'
import jsonlint from 'jsonlint-mod'

export const jsonLinter = (onLint?: (diagnostics: any[]) => void) => {
  return linter((view) => {
    const diagnostics = []
    try {
      jsonlint.parse(view.state.doc.toString())
    } catch (error) {
      const errorObj = error as any
      const message = errorObj?.message
      const loc = errorObj?.loc

      if (
        loc &&
        loc.first_line !== undefined &&
        loc.first_column !== undefined &&
        loc.last_line !== undefined &&
        loc.last_column !== undefined
      ) {
        const fromLine = Math.max(loc.first_line - 1, 0)
        const fromColumn = Math.max(loc.first_column - 1, 0)
        const toLine = Math.max(loc.last_line - 1, 0)
        const toColumn = Math.max(loc.last_column - 1, 0)

        const from = view.state.doc.line(fromLine + 1).from + fromColumn
        const to = view.state.doc.line(toLine + 1).from + toColumn

        diagnostics.push({
          from,
          to,
          severity: 'error',
          message,
        })
      } else {
        diagnostics.push({
          from: 0,
          to: view.state.doc.length,
          severity: 'error',
          message,
        })
      }
    }

    onLint && onLint(diagnostics)

    return diagnostics
  })
}
