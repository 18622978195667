import { TranslationsInterface } from '@store/Translations.store'

export const translations: TranslationsInterface = {
  pageTitle: 'DashboardPL',
  loginPage: {
    welcomeBack: 'Witaj!',
    enterEmail: 'Wpisz swój adres e-mail',
    logIn: 'Zaloguj się',
    redirecting: 'Przekierowanie',
    pleaseWaitRedirectionToMicrosoft: 'Za chwilę nastąpi przekierowanie na stronę Microsoft',
  },
  errorPage: {
    somethingWentWrong: 'Coś poszło nie tak',
    logOut: 'Wyloguj się',
  },
  menu: {
    selectTenant: 'wybierz klienta',
    transactions: 'Transakcje',
    createTransaction: 'Stwórz Transakcję',
    settings: 'Ustawienia',
    language: 'Język',
    theme: 'Motyw',
    logOut: 'Wyloguj',
  },
  transactionsPage: {
    pageTitle: 'Transakcje',
    unableToFetchTransactions: 'Nie można pobrać transakcji',
    selectTenant: 'wybierz klienta',
    setActiveTenant: 'Wybierz aktywnego klienta',
    prev: 'Poprzednia',
    next: 'Następna',
    of: 'z',
    filters: 'Filtry',
    clientId: 'ID klienta',
    transactionId: 'ID Transakcji',
    configurationId: 'Configuration ID',
    applicationNumber: 'Application Number',
    externalReferenceId: 'External Reference ID',
    modularization: 'Modularyzacja',
    status: 'Status',
    tags: 'Tagi',
    startDate: 'Data od',
    endDate: 'Data do',
    hideColumns: 'Ukryj kolumny',
    clearFilters: 'Wyczyść Filtry',
    selectPlaceholder: '-- wybierz --',
    noResults: 'brak wyników',
    all: 'wszystkie',
    completed: 'zakończone',
    expired: 'przedawnione',
    pending: 'oczekujące',
    select: 'wybierz',
    notAuthorized: 'Brak uprawnień',
    notAuthorizedMessage: 'Nie posiadasz uprawnień do przeglądania listy transakcji',
    copyToClipboard: 'Kopiuj do schowka',
  },
  singleTransactionPage: {
    goBack: 'Wróć',
    transaction: 'Transakcja',
    unableToFetchTransaction: 'Nie można pobrać transakcji',
    unableToDeletePiiData: 'Nie można wyczyścić danych',
    notAuthorized: 'Brak uprawnień',
    notAuthorizedMessage: 'Nie posiadasz uprawnień do przeglądania tej transakcji',
    basicInformation: 'Podstawowe informacje',
    showAuditLog: 'Wyświetl audit log',
    deletePiiData: 'Usuń dane Pii',
    showTransactionHistory: 'Wyświetl etapy transakcji',
    events: 'audit log',
    steps: 'etapy',
    deleteModalTitle: 'Usunąć dane PII?',
    confirm: 'Tak',
    decline: 'Nie',
  },
  singleTransactionAuditLog: {
    AuditLog: 'Audit Log',
    unableToFetchAuditLog: 'Nie można pobrać audit loga',
    prev: 'Poprzedni',
    next: 'Następny',
    noResults: 'brak wyników',
    type: 'Typ',
    publisher: 'Publisher',
    payload: 'Payload',
    timestamp: 'Timestamp',
    loadMore: 'Załaduj więcej',
  },
  singleTransactionHistory: {
    TransactionHistory: 'Etapy transakcji',
    unableToFetch: 'Nie można pobrać etapów transakcji',
    noResults: 'brak wyników',
    step: 'Etap',
    timestamp: 'Czas',
  },
  createTransactionPage: {
    goBack: 'Wróć',
    createTransaction: 'Stwórz Transakcję',
    noWriteRights: 'Brak praw do zapisu dla wybranego klienta',
    jsonValidated: 'poprawny format',
    errorinLine: 'niepoprawny format',
    configurationID: 'configuration ID',
    preferredLanguage: 'Preferowany Język',
    themeId: 'Theme ID',
    redirectURL: 'Redirect URL',
    phoneNumber: 'Numer Telefonu',
    email: 'E-mail',
    modularization: 'Modularyzacja',
    PDFdocument: 'Dokument PDF',
    uploadNewPDF: 'Wgraj nowy PDF',
    transactionId: 'Transaction ID',
    proceedToken: 'Proceed Token',
    proceedUrl: 'Proceed URL',
    clientID: 'Client ID',
    payload: 'Payload',
    newTransaction: 'Nowa Transakcja',
    editConfiguration: 'Edytuj Konfigurację',
    selectMock: 'Wybierz wzór',
    uploadPDFFile: 'Wgraj plik PDF',
    clickToSelectFile: 'Kliknij by wybrać plik',
    PDFfileInput: 'Plik PDF',
    pasteBase64: 'Wklej Base64',
    existingPDFmocks: 'Istniejące wzory PDF',
    pastePDFbase64: 'Wklej PDF (Base64)',
    PDFbase64Input: 'PDF base64',
    clear: 'Wyczyść',
    create: 'Stwórz',
    notAuthorized: 'Brak uprawnień',
    notAuthorizedMessage: 'Nie posiadasz uprawnień do tworzenia transakcji',
  },
  settingsPage: {
    pageTitle: 'Ustawienia',
    tagsSettings: 'Tagi',
    icons: 'tylko ikony',
    tags: 'tylko tagi',
    both: 'ikony + tagi',
    notAuthorized: 'Brak uprawnień',
    notAuthorizedMessage: 'Nie posiadasz uprawnień do zmiany ustawień',
    v3Settings: 'Modularyzacja (test)',
    on: 'włączone',
    off: 'wyłączone (domyślnie)',
  },
}
