import { observer } from 'mobx-react'
import { FC, useCallback, useState } from 'react'
import { Button } from '../generic/Button/Button'
import {
  PropertyName,
  PropertyValue,
  ResultApiUrl,
  ResultButtonRow,
  ResultErrorMessage,
  ResultItem,
  ResultStatus,
} from './RequestForm.styles'

import { TransactionResponseParsed } from './CreateTransationMethods'
import { useTheme } from 'styled-components'

interface ResultProps {
  response: TransactionResponseParsed
  index: number
  fetching: boolean
}

export const Result: FC<ResultProps> = observer(({ response, index, fetching }) => {
  const theme = useTheme()
  const [expanded, setExpanded] = useState(!index && !fetching)

  const copyPayload = useCallback(async (response: TransactionResponseParsed) => {
    const { transactionId, proceedToken, proceedUrl } = response

    await navigator.clipboard.writeText(
      JSON.stringify({
        transactionId,
        proceedToken,
        proceedUrl,
      })
    )
  }, [])

  return (
    <ResultItem
      key={index}
      onClick={() => {
        setExpanded(!expanded)
      }}
      expand={expanded && !response?.errorMessage}
      active={index === 0}
    >
      <ResultStatus status={response?.status === '200 OK' ? 'success' : 'failure'}>
        <h2>{response?.date}</h2>
        <span>{response?.status}</span>
      </ResultStatus>

      {!response?.errorMessage ? (
        <>
          <ResultApiUrl>{response?.apiUrl}</ResultApiUrl>
          <PropertyName expand={expanded}>Transaction ID</PropertyName>
          <PropertyValue expand={expanded}>{response?.transactionId}</PropertyValue>
          <PropertyName expand={expanded}>Process Token</PropertyName>
          <PropertyValue expand={expanded}>{response?.proceedToken}</PropertyValue>
          <PropertyName expand={expanded}>Proceed URL</PropertyName>
          <PropertyValue expand={expanded}>
            <a href={response?.proceedUrl} target="_blank" rel="noreferrer">
              {response?.proceedUrl}
            </a>
          </PropertyValue>
        </>
      ) : (
        <ResultErrorMessage>{response?.errorMessage}</ResultErrorMessage>
      )}

      <ResultButtonRow
        expand={expanded}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <Button
          onClick={() => {
            void copyPayload(response)
          }}
          {...theme.createTransaction.copyPayloadButton}
        >
          Copy result to clipboard
        </Button>
        <a href={response?.proceedUrl} target="_blank" rel="noreferrer">
          Open Proceed Url
        </a>
      </ResultButtonRow>
    </ResultItem>
  )
})
