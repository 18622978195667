import { FC, useContext, useState } from 'react'
import { Redirect } from 'wouter'
import { StoreContext } from '@components/App'
import { Spacer } from '@components/generic/Spacer/Spacer'
import { Menu } from '@components/Menu/Menu'
import { MobileHeader } from '@components/MobileHeader/MobileHeader'
import { Page, PageHeader, PageTitle, StyledPage } from './CreateTransactionPage.styles'
import { RequestForm } from '@components/RequestForm/RequestForm'
import { observer } from 'mobx-react'

type Tabs = 'object-mode' | 'form-mode'

export const CreateTransactionPage: FC = observer(() => {
  const store = useContext(StoreContext)

  const { createTransactionPage: trans } = store.TranslationsState.translations
  const { isUserWithAnyWriteRights } = store.AppState
  const { isAuthorized, isLogged } = store.Auth

  const [activeTab, setActiveTab] = useState<Tabs>('object-mode')

  return (
    <StyledPage>
      <Page>
        <PageHeader>
          <PageTitle>{trans.createTransaction}</PageTitle>
        </PageHeader>
        <Spacer size={19}></Spacer>

        <RequestForm />
      </Page>
      <MobileHeader />
      <Menu />
      {isLogged && !isAuthorized ? (
        <Redirect to={`/unauthorized?title=${trans.notAuthorized}}&description=${trans.notAuthorizedMessage}`} />
      ) : (
        <></>
      )}
      {isLogged && isUserWithAnyWriteRights ? <></> : <Redirect to="/unauthorized" />}
      {!isLogged ? <Redirect to={'/login'} /> : <></>}
    </StyledPage>
  )
})
