import styled from 'styled-components'
import { Animatable } from '@styles/generic.styles'

export const Container = styled.div<{
  $width?: string
  $height?: string
  $maxHeight?: string
}>`
  display: flex;
  flex-direction: column;
  width: ${({ $width }) => $width || '100%'};
  height: ${({ $height }) => $height || '100%'};
  max-height: ${({ $maxHeight }) => $maxHeight || '100%'};
  overflow-y: auto;
  border-radius: ${({ theme }) => theme.json.borderRadius};

  position: relative;

  & > div {
    height: 100%;

    & > div {
      height: 100%;
    }
  }
`

export const JSONViewerTooltip = styled.div`
  position: absolute;

  top: 8;
  right: 16;
  z-index: 1;

  display: flex;
  gap: 8px;

  ${Animatable}
`

export const TooltipItem = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.tertiary2};
  width: 32px;
  height: 32px;

  border-radius: 8px;
  cursor: pointer;

  opacity: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }
`
