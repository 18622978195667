import { action, makeAutoObservable, runInAction } from 'mobx'
import { tagsOptions } from '../constants/settings'
import { makeLocalStorage } from '../helpers/localStorageHelpers'
import { RootStore } from './Root.store'

export class SettingsStore {
  tagsSettings = tagsOptions[0].value

  private rootStore: RootStore

  constructor(rootStore) {
    makeAutoObservable(this)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore

    makeLocalStorage(this, 'settingsStore', ['tagsSettings'])
  }

  resetSettings(): void {
    runInAction(() => {
      this.tagsSettings = tagsOptions[0].value
    })
  }

  @action.bound setTagsSettings = (tagsSettings: string): void => {
    this.tagsSettings = tagsSettings
  }
}
