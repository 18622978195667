import styled, { css } from 'styled-components'
import { Animatable } from '@styles/generic.styles'
import SearchIconLightTheme from '@components/Icon/SearchIconLightTheme.svg'
import SearchIconDarkTheme from '@components/Icon/SearchIconDarkTheme.svg'

export const StyledPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-self: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    height: auto;
    display: block;
    position: relative;
  }
`

export const Page = styled.div<{
  menuOpened?: boolean
}>`
  flex: 1;
  width: 100%;
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.tertiary2};
  padding: 39px 30px 30px;
  min-height: 100vh;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    height: auto;
    min-height: 100%;
    padding: 0;
    margin-top: 65px;
  }

  ${Animatable}
`

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0 30px;
  }
`

export const PageTitle = styled.h3`
  color: ${({ theme }) => theme.colors.secondary2};
  font-size: 1.88rem;
  line-height: 1.22;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 1.25rem;
    padding: 10px 0;
  }
`

export const PageContainer = styled.div<{
  noPadding?: boolean
  open?: boolean
  enableHiddenColumnsFilter?: boolean
}>`
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary2};
  background-color: ${({ theme }) => theme.colors.quaternary};
  font-family: ${({ theme }) => theme.fonts.familyVariant};
  font-size: 0.88rem;
  border-radius: 17px;

  ${({ noPadding }) =>
    !noPadding &&
    css`
      padding: 15px 20px;
    `}
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0;
    display: block;
    overflow: hidden;
    border-radius: 0;
    ${({ open, enableHiddenColumnsFilter }) =>
      open &&
      css`
        height: ${enableHiddenColumnsFilter ? '760px' : '660px'};
      `}

    ${({ open }) =>
      !open &&
      css`
        height: 0px;
      `}
    
    ${Animatable};
  }
`

export const PageFiltersOpener = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: block;
    color: ${({ theme }) => theme.colors.secondary2};
    background-color: ${({ theme }) => theme.colors.quaternary};
    padding: 18px 30px;
    display: flex;
    justify-content: space-between;
  }
`

export const TableContainer = styled.div<{
  loader?: boolean
}>`
  width: 100%;
  min-height: calc(100vh - 360px);
  color: ${({ theme }) => theme.colors.secondary2};
  background-color: ${({ theme }) => theme.colors.quaternary};
  font-family: ${({ theme }) => theme.fonts.familyVariant};
  font-size: 0.88rem;
  border-radius: 17px;
  overflow-x: auto;

  ${({ loader }) =>
    loader &&
    css`
      height: 100%;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      padding: 0px;
    `}

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => (theme.colors.scrollTrackColor ? theme.colors.scrollTrackColor : '#ebebeb')};
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: ${({ theme }) => (theme.colors.scrollHandleColor ? theme.colors.scrollHandleColor : '#6d6d6d')};
  }

  @media (max-width: 1440px) {
    min-height: calc(100vh - 380px);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    min-height: calc(100vh - 150px);
    border-radius: 0;
  }
`

export const FilterRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: repeat(4, minmax(20%, 1fr));
    grid-template-columns: repeat(3, minmax(20%, 1fr));
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin: 15px 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const FillingFilter = styled.div`
  flex-grow: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex: 1 0 150px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }
`

export const FixedFilter = styled.div<{
  hideOnDesktop?: boolean
}>`
  flex-grow: 0;
  flex-shrink: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-basis: 150px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }
  ${({ hideOnDesktop }) =>
    hideOnDesktop &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        display: none;
      }
    `}
`

export const FixedFilterButton = styled.div<{
  hideOnDesktop?: boolean
}>`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: flex-end;
  & > button {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-basis: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
    & > button {
      display: block;
    }

    & > div {
      display: none;
    }
  }
  ${({ hideOnDesktop }) =>
    hideOnDesktop &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        display: none;
      }
    `}
`

export const SearchInput = styled.input<{
  isDarkTheme?: boolean
}>`
  width: 100%;
  min-width: 114px;
  height: 35px;
  font-family: ${({ theme }) => theme.fonts.familyVariant};
  padding: 6px 6px 6px 35px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.quinary};
  color: ${({ theme }) => theme.colors.secondary2};
  background-color: inherit;
  background-image: url(${(props) => (props.isDarkTheme ? SearchIconDarkTheme : SearchIconLightTheme)});
  background-position: 10px 10px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.secondary2};
  }

  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.quinary};
    background-color: ${({ theme }) => theme.colors.quinary};
    color: ${({ theme }) => theme.colors.septenary2};
    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.quinary};
    }
  }

  ${Animatable}
`

export const TenantsList = styled.select`
  float: right;
  padding: 4px 12px;
  height: 35px;
  border-radius: 24px;
  font-family: ${({ theme }) => theme.fonts.family};
  border-width: 0px;
  color: ${({ theme }) => theme.colors.secondary2};
  background: transparent;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.quinary};
  }
`

export const Table = styled.table`
  table-layout: fixed;
  border: 1px solid;
  width: 90%;
  font-size: 1rem;

  thead,
  tbody {
    display: block;
  }

  tbody {
    height: 800px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  th,
  td {
    border: 1px solid;
  }
`

export const Pagination = styled.div<{ justifyContent?: string }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'space-between')};
  align-items: center;
  gap: 10px;
  padding: 10px 20px;

  span {
    color: ${({ theme }) => theme.colors.secondary2};
  }
`

export const TitleRefreshWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`
