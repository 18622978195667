import styled, { css } from 'styled-components'

interface SpacerProps {
  $width?: number
  $height?: number
  $hideOnDesktop?: boolean
  $mobileWidth?: number
  $mobileHeight?: number
  $hideOnMobile?: boolean
}

export const StyledSpacer = styled.div<SpacerProps>`
  ${({ $hideOnDesktop }) =>
    $hideOnDesktop &&
    css`
      display: none;
    `};

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width}px;
    `};

  ${({ $height }) =>
    $height
      ? css`
          height: ${$height}px;
        `
      : css`
          flex: 1;
        `};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    ${({ $hideOnMobile }) =>
      $hideOnMobile
        ? css`
            display: none;
          `
        : css`
            display: block;
          `};

    ${({ $mobileWidth }) =>
      $mobileWidth &&
      css`
        width: ${$mobileWidth}px;
      `};

    ${({ $mobileHeight }) =>
      $mobileHeight
        ? css`
            height: ${$mobileHeight}px;
          `
        : css`
            flex: 1;
          `};
  }
`
