import { FC, MouseEventHandler, PropsWithChildren } from 'react'
import { StyledAuditLogButton } from './AuditLogButton.styles'

type Props = {
  onClick: MouseEventHandler
  title: string
}

export const AuditLogButton: FC<PropsWithChildren<Props>> = (props) => {
  return (
    <StyledAuditLogButton
      onClick={props.onClick}
      title={props.title}
    >
    <svg version="1.1" id="Layer_1" fill="currentColor" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 24 24" xmlSpace="preserve">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path d="M23.5,22h-13c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H23V8.707L17.293,3H13.5C13.224,3,13,2.776,13,2.5
                    S13.224,2,13.5,2h4c0.133,0,0.26,0.053,0.354,0.146l6,6C23.947,8.24,24,8.367,24,8.5v13C24,21.776,23.776,22,23.5,22z"/>
                </g>
                <g>
                  <path d="M23.5,9h-6C17.224,9,17,8.776,17,8.5v-6C17,2.224,17.224,2,17.5,2S18,2.224,18,2.5V8h5.5C23.776,8,24,8.224,24,8.5
                    S23.776,9,23.5,9z"/>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g>
          <path d="M20.5,13h-8c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h8c0.276,0,0.5,0.224,0.5,0.5S20.776,13,20.5,13z"/>
        </g>
        <g>
          <path d="M20.5,16h-8c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h8c0.276,0,0.5,0.224,0.5,0.5S20.776,16,20.5,16z"/>
        </g>
        <g>
          <path d="M20.5,19h-8c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h8c0.276,0,0.5,0.224,0.5,0.5S20.776,19,20.5,19z"/>
        </g>
        <g>
          <g>
            <path d="M9.5,24c-0.723,0-1.433-0.129-2.111-0.382l-5.767-2.164C0.652,21.092,0,20.151,0,19.114V4.988
              c0-1.113,0.716-2.075,1.782-2.395l6.659-1.998l0,0C9.759,0.201,11.125,0,12.5,0C13.327,0,14,0.673,14,1.5v5.279
              c0,0.646-0.412,1.218-1.025,1.423l-1.633,0.544C11.137,8.814,11,9.005,11,9.221V22.5C11,23.327,10.327,24,9.5,24z M8.728,1.554
              L2.069,3.551C1.43,3.743,1,4.321,1,4.988v14.126c0,0.622,0.391,1.187,0.973,1.403l5.767,2.164C8.305,22.893,8.897,23,9.499,23
              C9.775,23,10,22.775,10,22.5V9.221c0-0.646,0.412-1.218,1.025-1.423l1.633-0.544C12.863,7.186,13,6.995,13,6.779V1.5
              C13,1.224,12.776,1,12.5,1C11.222,1,9.953,1.186,8.728,1.554L8.728,1.554z"/>
          </g>
        </g>
      </g>
    </svg>

    </StyledAuditLogButton>
  )
}
