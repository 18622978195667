import { action, makeAutoObservable } from 'mobx'
import { TagFilterObject } from '@/components'
import { axiosInstance } from '@/axios/AxiosInstance'
import { TransactionListItem } from '@pages/TransactionListPage/TransactionPage'
import qs from 'qs'
import { parseQueryParams } from '@methods/paramsComposer'
import { RootStore } from './Root.store'

const formatDate = (date: { valueOf: () => unknown; getTime: () => number }) =>
  date.valueOf() ? Math.round(date?.getTime() / 1000 || 0) : undefined

const parseTagFilter = (status: 'include' | 'exclude' | '', tagFilterArr: TagFilterObject[]): string[] => {
  return tagFilterArr.filter((tag) => tag.state === status).map((tag) => tag.name)
}

export class MerchantsTableStore {
  fetching = false
  total = 0
  limit = 0
  transactions: TransactionListItem[] = []

  private rootStore: RootStore

  constructor(rootStore) {
    makeAutoObservable(this)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore
  }

  @action.bound setFetching(fetching: boolean): void {
    this.fetching = fetching
  }

  @action.bound setTotal(total: number): void {
    this.total = total
  }

  @action.bound setLimit(limit: number): void {
    this.limit = limit
  }

  @action.bound setTransactions(transactions: TransactionListItem[]): void {
    this.transactions = transactions
  }

  getTransactions = () => {
    this.setFetching(true)
    this.fetchTransactions()
      .finally(() => this.setFetching(false))
      .catch(() =>
        this.rootStore.Filters.setNotification(
          this.rootStore.TranslationsState.translations.transactionsPage.unableToFetchTransactions
        )
      )
  }

  fetchTransactions = async () => {
    if (
      this.rootStore.Auth.currentUserId?.length &&
      this.rootStore.AppState.selectedTenant?.length &&
      this.rootStore.AppState.selectedTenant !== 'null' &&
      this.rootStore.AppState.selectedTenant !== 'undefined'
    ) {
      const response = await axiosInstance.get<{
        items: TransactionListItem[]
        total: number
        limit: number
        nextPageToken?: string
      }>('/transactions', {
        withCredentials: true,
        params: parseQueryParams({
          tenantId: this.rootStore.AppState.selectedTenant,
          transactionId: this.rootStore.Filters.filter || undefined,
          configurationId: this.rootStore.Filters.configurationId || undefined,
          externalUserRefId: this.rootStore.Filters.userRef || undefined,
          transactionCreatedAtStart: formatDate(this.rootStore.Filters.transactionStart),
          transactionCreatedAtEnd: formatDate(this.rootStore.Filters.transactionEnd),
          status:
            this.rootStore.Filters.selectedStatus && this.rootStore.Filters.selectedStatus !== 'all'
              ? this.rootStore.Filters.selectedStatus
              : undefined,
          tag: parseTagFilter('include', this.rootStore.Filters.tagFilterArr),
          tagNotPresent: parseTagFilter('exclude', this.rootStore.Filters.tagFilterArr),
          nextPageToken: this.rootStore.Filters.pageTokenArray[this.rootStore.Filters.currentPageIndex],
        }),
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        },
      })

      this.setTotal(response.data.total)
      this.setLimit(response.data.limit)

      if (response.data?.nextPageToken?.length) {
        this.rootStore.Filters.setPageTokenByIndex(
          this.rootStore.Filters.currentPageIndex + 1,
          response.data.nextPageToken
        )
      }

      this.setTransactions(response.data.items)
    }
  }
}
