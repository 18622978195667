import { FC, useEffect, useMemo, useRef, useState, useContext, MouseEvent } from 'react'
import { Button } from '@components/generic/Button/Button'
import { useLocation } from 'wouter'
import { StoreContext } from '@components/App'
import { TransactionListItem } from '@/pages/TransactionListPage/TransactionPage'
import { getFormatedDate } from '@methods/handleDate'
import { checkTranslation } from '@methods/handleTexts'

import {
  StyledTable,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderCellTitle,
  Resizer,
  TableRow,
  TableRowCell,
  CellWrapper,
  PillsCellWrapper,
} from './Table.styles'

import {
  GroupingState,
  useReactTable,
  getExpandedRowModel,
  getGroupedRowModel,
  getCoreRowModel,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'
import { TableRowExpand } from './TableRowExpand'
import { observer } from 'mobx-react'
import { useTheme } from 'styled-components'
import { ModularizationIcon } from '@components/Icon/components/ModularizationIcon'
import { ContextMenu, Pills, Status, TableGrouping } from '@/components'

const now = new Date()
const compareDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14)
const compareDateUnixTimeSeconds = Math.floor(compareDate.getTime() / 1000)

const defaultContextMenu = {
  position: {
    x: 0,
    y: 0,
  },
  toggled: false,
  value: '',
}

type Props = {
  transactions: TransactionListItem[]
  timezone: string
  onClickConfigurationId: (id: string) => void
  onClickOldTransaction: (transactionId: string, timestamp: number) => void
  hiddenColumns?: { value: string; label: string }[]
}

export const Table: FC<Props> = observer(({ transactions, timezone, hiddenColumns, onClickOldTransaction }) => {
  const store = useContext(StoreContext)
  const { language, translations } = store.TranslationsState
  const theme = useTheme()
  const { selectedTenant } = store.AppState
  const { transactionsPage: trans } = translations
  const ref = useRef<HTMLDivElement>(null)
  const contextMenuRef = useRef<HTMLDivElement>(null)

  const [location, setLocation] = useLocation()
  const iconNodeNames = ['svg', 'path']

  const localGroupingState = JSON.parse(localStorage.getItem('groupingState')) as GroupingState
  const localWidthsState = JSON.parse(localStorage.getItem('tableWidthsState')) as { [key: string]: number }

  const width = window.innerWidth <= 820 ? 1000 / 4.5 : (window.innerWidth - 60) / 4.5

  const defaultColumns: ColumnDef<TransactionListItem>[] = useMemo(
    () => [
      {
        accessorKey: 'V3',
        accessorFn: (row) => row.isV3,
        cell: (info) => (
          <CellWrapper
            onClick={(e) => {
              e.stopPropagation()
            }}
            title={trans.modularization}
          >
            {info.getValue() && <ModularizationIcon size={16} />}
          </CellWrapper>
        ),
        enableGrouping: false,
        header: '',
        size: 50,
        minSize: 50,
        maxSize: 50,
      },
      {
        accessorKey: 'Transaction_ID',
        accessorFn: (row) => row.transactionId,
        cell: (info) => (
          <CellWrapper
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <div>{String(info.getValue())}</div>
          </CellWrapper>
        ),
        enableGrouping: false,
        header: trans.transactionId,
        size:
          localWidthsState && localWidthsState['--Transaction_ID-size']
            ? localWidthsState['--Transaction_ID-size']
            : width,
      },
      {
        accessorKey: 'Configuration_ID',
        accessorFn: (row) => row.configurationId,
        cell: (info) => (
          <CellWrapper
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <div>{String(info.getValue())}</div>
          </CellWrapper>
        ),
        enableGrouping: false,
        header: trans.configurationId,
        size:
          localWidthsState && localWidthsState['--Configuration_ID-size']
            ? localWidthsState['--Configuration_ID-size']
            : width / 2,
      },
      {
        accessorKey: 'Application_Number',
        accessorFn: (row) => row.externalUserRefId || '-',
        cell: (info) => (
          <CellWrapper
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <div>{String(info.getValue())}</div>
          </CellWrapper>
        ),
        enableGrouping: true,
        header: trans[checkTranslation('externalUserRefId', selectedTenant) || 'externalReferenceId'] as string,
        size:
          localWidthsState && localWidthsState['--Application_Number-size']
            ? localWidthsState['--Application_Number-size']
            : width / 2,
      },
      {
        accessorKey: 'Create_Date',
        accessorFn: (row) => getFormatedDate(row.creationUnixTimeSeconds),
        cell: (info) => (
          <CellWrapper
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <div>{String(info.getValue())}</div>
          </CellWrapper>
        ),
        enableGrouping: false,
        header: `${trans.startDate} (${timezone})`,
        size:
          localWidthsState && localWidthsState['--Create_Date-size']
            ? localWidthsState['--Create_Date-size']
            : width / 2,
      },
      {
        accessorKey: 'Completed_Date',
        accessorFn: (row) => (row.completionUnixTimeSeconds ? getFormatedDate(row.completionUnixTimeSeconds) : '-'),
        cell: (info) => (
          <CellWrapper
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <div>{String(info.getValue())}</div>
          </CellWrapper>
        ),
        enableGrouping: false,
        header: `${trans.endDate} (${timezone})`,
        size:
          localWidthsState && localWidthsState['--Completed_Date-size']
            ? localWidthsState['--Completed_Date-size']
            : width / 2,
      },
      {
        accessorKey: 'Status',
        accessorFn: (row) => row.status,
        cell: (info) => (
          <CellWrapper
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <div>
              <Status status={String(info.getValue())}>{String(info.getValue())}</Status>
            </div>
          </CellWrapper>
        ),
        enableGrouping: false,
        header: trans.status,
        size: localWidthsState && localWidthsState['--Status-size'] ? localWidthsState['--Status-size'] : width / 2,
      },
      {
        accessorKey: 'Tags',
        accessorFn: (row) => row?.tags,
        cell: (row) => {
          return (
            <PillsCellWrapper
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <div>
                <Pills
                  tags={row.getValue() as string[]}
                  rowId={row.row.id}
                  position={data.length - row.row.index > 5 ? 'bottom' : 'top'}
                  hoverPills
                />
              </div>
            </PillsCellWrapper>
          )
        },
        enableGrouping: false,
        header: trans.tags,
        size: localWidthsState && localWidthsState['--Tags-size'] ? localWidthsState['--Tags-size'] : width,
      },
    ],
    [width, language]
  )

  useEffect(() => {
    setColumnVisibility(
      hiddenColumns.reduce((acc, column) => {
        return { ...acc, [column.label]: false }
      }, {})
    )
  }, [hiddenColumns])

  useEffect(() => {
    setData([...transactions])
  }, [transactions])

  useEffect(() => {
    setColumns(() => [...defaultColumns])
  }, [defaultColumns])

  const [data, setData] = useState(() => [...transactions])
  const [columns, setColumns] = useState<typeof defaultColumns>(() => [...defaultColumns])
  const [columnVisibility, setColumnVisibility] = useState({})
  const [grouping, setGrouping] = useState<GroupingState>(localGroupingState || [])
  const [contextMenu, setContextMenu] = useState(defaultContextMenu)

  useEffect(() => {
    localStorage.setItem('groupingState', JSON.stringify(grouping))
  }, [grouping])

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      grouping,
    },
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    onGroupingChange: setGrouping,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
    defaultColumn: {
      minSize: 50,
    },
  })

  const resetContextMenu = () => {
    setContextMenu(defaultContextMenu)
  }

  useEffect(() => {
    function handler(e) {
      if (contextMenuRef.current) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        if (!contextMenuRef.current.contains(e.target)) {
          resetContextMenu()
        }
      }
    }

    document.addEventListener('click', handler)

    return () => {
      document.removeEventListener('click', handler)
    }
  }, [])

  const handleRightClick = (e: MouseEvent, value: any) => {
    e.preventDefault()

    if (!iconNodeNames.includes((e.target as Element).nodeName)) {
      setContextMenu({
        value: value as string,
        position: {
          x: e.clientX,
          y: e.clientY,
        },
        toggled: true,
      })
    }
  }

  const handleClick = (e: MouseEvent, transactionId: string, isV3?: string) => {
    e.preventDefault()

    if (!iconNodeNames.includes((e.target as Element).nodeName)) {
      const url = `/transactions/${transactionId}?tenantId=${selectedTenant}&isV3=${isV3}`

      if (e.ctrlKey || e.button === 1) {
        window.open(url, '_blank')
      } else {
        if (e.button !== 2) {
          setLocation(url)
        }
      }
    }
  }

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders()
    const colSizes: { [key: string]: number } = {}
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]
      colSizes[`--${header.id}-size`] = header.getSize()
    }
    localStorage.setItem('tableWidthsState', JSON.stringify(colSizes))

    return colSizes
  }, [table.getState().columnSizingInfo])

  return (
    <StyledTable className="overflow-x-auto" ref={ref}>
      <TableContainer
        {...{
          style: {
            ...columnSizeVars,
            width: table.getTotalSize(),
          },
        }}
      >
        {table.getHeaderGroups().map((headerGroup) => (
          <TableHeader key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHeaderCell key={header.id} style={{ width: `calc(var(--${header?.id}-size) * 1px)` }}>
                {header.isPlaceholder ? null : (
                  <TableHeaderCellTitle>
                    {flexRender(header.column.columnDef.header, header.getContext())}{' '}
                    {header.column.getCanGroup() ? (
                      <Button
                        {...{
                          onClick: header.column.getToggleGroupingHandler(),
                          clickable: true,
                        }}
                        {...theme.buttons.groupingDataButton}
                      >
                        {header.column.getIsGrouped() ? (
                          <TableGrouping grouped tooltip="Ungroup data" />
                        ) : (
                          <TableGrouping tooltip="Group data" />
                        )}
                      </Button>
                    ) : null}
                  </TableHeaderCellTitle>
                )}
                <Resizer
                  onMouseDown={header.getResizeHandler()}
                  onTouchStart={header.getResizeHandler()}
                  className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                />
              </TableHeaderCell>
            ))}
          </TableHeader>
        ))}

        {table.getRowModel().rows.map((row) => {
          const disabled = row.original.creationUnixTimeSeconds < compareDateUnixTimeSeconds
          return (
            <TableRow key={row.id} disabled={disabled}>
              {row.getVisibleCells().map((cell) => (
                <TableRowCell
                  key={cell.id}
                  onContextMenu={(e) => handleRightClick(e, cell.getValue())}
                  {...{
                    style: { width: `calc(var(--${cell.column.id}-size) * 1px)` },
                    className: cell.getIsGrouped() || cell.getIsAggregated() ? 'groupRow' : null,
                    onClick: !disabled
                      ? cell.getIsGrouped() || cell.getIsAggregated()
                        ? row.getToggleExpandedHandler()
                        : (e) => handleClick(e, row.original.transactionId)
                      : () =>
                          onClickOldTransaction(
                            row.original.transactionId,
                            row.original.creationUnixTimeSeconds
                              ? Number(row.original.creationUnixTimeSeconds)
                              : undefined
                          ),
                    onMouseUp:
                      !disabled && !cell.getIsGrouped() && !cell.getIsAggregated()
                        ? (e) => handleClick(e, row.original.transactionId, row.original.isV3 ? 'on' : 'off')
                        : undefined,
                  }}
                >
                  {cell.getIsGrouped() ? (
                    <>
                      {row.getIsExpanded() ? <TableRowExpand /> : <TableRowExpand rightPosition />}{' '}
                      {flexRender(cell.column.columnDef.cell, cell.getContext())} ({row.subRows.length})
                    </>
                  ) : cell.getIsAggregated() ? (
                    flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext())
                  ) : cell.getIsPlaceholder() ? null : (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )}
                </TableRowCell>
              ))}
            </TableRow>
          )
        })}
      </TableContainer>
      <ContextMenu
        value={contextMenu.value}
        contextMenuRef={contextMenuRef}
        isToggled={contextMenu.toggled}
        positionX={contextMenu.position.x}
        positionY={contextMenu.position.y}
        onClose={resetContextMenu}
        buttonText={trans.copyToClipboard}
      ></ContextMenu>
    </StyledTable>
  )
})
