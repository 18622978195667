import styled, { css } from 'styled-components'

export const StyledPage = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column-reverse;

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
  }
`

export const Page = styled.div`
  flex: 1;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.tertiary2};
  padding: 39px 30px 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding-top: 80px;
  }
`

export const PageContainer = styled.div<{ noPadding?: boolean }>`
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary2};
  background-color: ${({ theme }) => theme.colors.quaternary};
  font-family: ${({ theme }) => theme.fonts.familyVariant};
  font-size: 0.88rem;
  border-radius: 17px;

  ${({ noPadding }) =>
    !noPadding &&
    css`
      padding: 15px 20px;
    `}
`

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0 30px;
    justify-content: space-between;
  }
`

export const PageTitle = styled.h3`
  color: ${({ theme }) => theme.colors.secondary2};
  font-size: 1.88rem;
  line-height: 1.22;
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 1.25rem;
    padding: 10px 0;
  }
`

export const CopyToClipboard = styled.span`
  display: inline-block;
  cursor: pointer;
`

export const TransactionForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const FormItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 10px;
  }

  .json-editor {
    border-radius: 17px;
    font-size: 24px;
  }
`

export const FormTop = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    gap: 16px;
  }
`

export const FormBottom = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 32px;
  align-items: center;

  & > * {
    max-width: 300px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;

    & > * {
      max-width: 100%;
      width: 100%;
    }
  }
`

export const TransactionResponseData = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
    margin-bottom: 32px;
  }
`
