import styled, { css } from 'styled-components'

export const StyledLanguageSwitcher = styled.div<{
  open: boolean
}>`
  width: calc(100% - 2 * 15px);
  margin: 0 15px;
  color: ${({ theme }) => theme.colors.senary};

  & .select-input__control {
    padding: 4px !important;
  }

  & .select-input__value-container {
    padding: 0;
  }

  & .select-input__indicator {
    padding-left: 0 !important
  }

  ${({ open }) =>
    !open &&
      css`
        & > span {
          display: none;
        }

        & .select-input__control {
          min-width: 60px !important;
        }
      `
  }
`
