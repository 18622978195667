import styled from 'styled-components'

export const TabbedContent = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 12px;
`

export const Nav = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: left;
`

export const Tab = styled.div`
  display: none;
  box-sizing: border-box;
  padding: 20px;
  color: ${({ theme }) => theme.colors.secondary2};
  background-color: ${({ theme }) => theme.colors.quaternary};
  font-family: ${({ theme }) => theme.fonts.familyVariant};
  font-size: 0.88rem;
  border-radius: 0px 17px 17px 17px;

  &.active {
    display: block;
    background-color: #f3f3f3;
  }
`
