import React from 'react'
import { tags } from '@lezer/highlight'
import { HighlightStyle } from '@codemirror/language'
import { useDarkMode } from '@hooks/useDarkmode'

export const useHighlightStyle = (): HighlightStyle => {
  const { isDarkMode } = useDarkMode()

  /* eslint-disable */
  return React.useMemo(
    () =>
      HighlightStyle.define([
        {
          tag: tags.link,
          textDecoration: 'underline',
        },
        {
          tag: tags.heading,
          textDecoration: 'underline',
          fontWeight: 'bold',
        },
        {
          tag: tags.meta,
          color: isDarkMode ? '#43978b' : '#2a719b',
        },
        {
          tag: tags.emphasis,
          fontStyle: 'italic',
        },
        {
          tag: tags.strong,
          fontWeight: 'bold',
        },
        {
          tag: tags.strikethrough,
          textDecoration: 'line-through',
        },
        {
          tag: tags.keyword,
          color: isDarkMode ? '#f746ec' : '#8e0b99',
        },
        {
          tag: [tags.atom, tags.bool, tags.url, tags.contentSeparator, tags.labelName],
          color: isDarkMode ? '#978ed3' : '#121cb1',
        },
        {
          tag: [tags.literal, tags.inserted],
          color: isDarkMode ? '#E02569' : '#E02569',
        },
        {
          tag: [tags.deleted],
          color: isDarkMode ? '#d22c2c' : '#aa1111',
        },
        {
          tag: [tags.brace],
          color: isDarkMode ? '#fff' : '#ddd',
        },
        {
          tag: [tags.bracket],
          color: isDarkMode ? '#4b88d7' : '#0d64d5',
        },
        {
          tag: [tags.string],
          color: isDarkMode ? '#b39ad4' : '#7D18FF',
        },
        {
          tag: [tags.regexp, tags.escape, tags.special(tags.string)],
          color: '#ee4400',
        },
        {
          tag: tags.definition(tags.variableName),
          color: isDarkMode ? '#5279ec' : '#255fb9',
        },
        {
          tag: tags.local(tags.variableName),
          color: '#3300aa',
        },
        {
          tag: [tags.typeName, tags.namespace],
          color: isDarkMode ? '#ec4837' : '#b7382b',
        },
        {
          tag: tags.className,
          color: '#116677',
        },
        {
          tag: [tags.special(tags.variableName), tags.macroName],
          color: '#225566',
        },
        {
          tag: tags.definition(tags.propertyName),
          color: '#0000cc',
        },
        {
          tag: tags.comment,
          color: isDarkMode ? '#738284' : '#6b7677',
        },
        {
          tag: tags.invalid,
          color: '#ff0000',
        },
        {
          tag: tags.propertyName,
          color: isDarkMode ? '#087e57' : '#1A6351',
        },
        {
          tag: tags.bool,
          color: isDarkMode ? '#8e0b99' : '#8e0b99',
        },
      ]),
    [isDarkMode]
  )
}
