import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

interface InputProps {
  bgColor?: string
  padding?: string
  borderRadius?: string
  border?: string
  boxShadow?: string
  margin?: string
  color?: string
  colorFocus?: string
  bgColorFocus?: string
  borderFocus?: string
  bgColorDisabled?: string
  borderDisabled?: string
  colorDisabled?: string
  fontSize?: string
  fontWeight?: string
  outline?: string
}

export const Input = styled.input<InputProps>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : 'white')};
  padding: ${(props) => (props.padding ? props.padding : '14px')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '8px')};
  border: ${(props) => (props.border ? props.border : '1px solid silver')};
  box-shadow: ${(props) => (props.border ? props.border : 'none')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  color: ${(props) => (props.color ? props.color : 'gray')};
  outline: none;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};

  &:focus {
    color: ${(props) => (props.colorFocus ? props.colorFocus : 'black')};
    background-color: ${(props) => (props.bgColorFocus ? props.bgColorFocus : 'white')};
    border: ${(props) => (props.borderFocus ? props.borderFocus : '1px solid silver')};
    outline: ${(props) => (props.outline ? props.outline : '2px solid orange')};
  }

  &:disabled,
  &[disabled] {
    color: ${(props) => (props.colorDisabled ? props.colorDisabled : 'silver')};
    background-color: ${(props) => (props.bgColorDisabled ? props.bgColorDisabled : 'gray')};
    border: ${(props) => (props.borderDisabled ? props.borderDisabled : '1px solid silver')};
  }
`

interface ErrorMsgProps {
  color: string
}

export const ErrorMsg = styled.span<ErrorMsgProps>`
  width: 100%;
  color: ${(props) => (props.color ? props.color : 'red')};
  padding-top: 8px;
`
