import { FC, PropsWithChildren, useCallback, useContext } from 'react'
import { AxiosError } from 'axios'
import { axiosInstance } from './AxiosInstance'
import { StoreContext } from '@components/App'
import { observer } from 'mobx-react'

const errorStatuses = [401, 402, 403, 500]

const AxiosErrorHandler: FC<PropsWithChildren> = observer(({ children }) => {
  const store = useContext(StoreContext)

  const errorHandler = useCallback(
    (status: number) => {
      if (errorStatuses.includes(status)) {
        if (status === 403 && store.Auth.isLogged) {
          store.Auth.setIsAuthorized(false)
        } else {
          store.Auth.setIsLogged(false)
          store.Auth.setShouldLogout(true)
          store.Auth.setCurrentUserId('')
        }
      }
    },
    [store.Auth]
  )

  axiosInstance.interceptors.response.use(
    (response) => {
      // failsafe to look for error codes in response
      errorHandler(response?.status)
      return response
    },
    async (error: AxiosError) => {
      errorHandler(error.response?.status)
      return Promise.reject(error)
    }
  )

  return children
})

export default AxiosErrorHandler
