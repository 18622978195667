import { Container, Wrap } from './LoadingSpinner.styles'

interface LoadingSpinnerProps {
  width?: string
  height?: string
  bgColor: string
  primary: string
  padding?: string
  thickness?: number
  bgIframe?: string
}

export const LoadingSpinner = ({
  width,
  height,
  bgColor,
  primary,
  padding,
  thickness,
  bgIframe,
}: LoadingSpinnerProps) => {
  return (
    <Wrap padding={padding} height={height}>
      <Container
        id="loading"
        width={width || '80px'}
        bgColor={bgColor}
        primary={primary}
        thickness={thickness}
        bgIframe={bgIframe}
      ></Container>
    </Wrap>
  )
}
