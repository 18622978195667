import { useLocalStorage, useMediaQuery } from 'usehooks-ts'

const COLOR_SCHEME_QUERY = '(prefers-color-scheme: dark)'
const LOCAL_STORAGE_KEY = 'dark-mode'

type DarkModeOptions = {
  defaultValue?: boolean
  localStorageKey?: string
  initializeWithValue?: boolean
}

type DarkModeReturn = {
  isDarkMode: boolean
  toggle: () => void
  enable: () => void
  disable: () => void
  set: (value: boolean) => void
}

export function useDarkMode(options: DarkModeOptions = {}): DarkModeReturn {
  const { localStorageKey = LOCAL_STORAGE_KEY, initializeWithValue = true } = options

  const isDarkOS = useMediaQuery(COLOR_SCHEME_QUERY, {
    initializeWithValue,
  })

  const [isDarkMode, setDarkMode] = useLocalStorage<boolean>(localStorageKey, isDarkOS ?? false, {
    initializeWithValue,
  })

  // resetting darkMode if os prefers changes each time user refreshes the page
  // Update darkMode if os prefers changes
  // useIsomorphicLayoutEffect(() => {
  //   if (isDarkOS !== isDarkMode) {
  //     setDarkMode(isDarkOS)
  //   }
  // }, [isDarkOS])

  return {
    isDarkMode,
    toggle: () => {
      console.log('toggle')
      setDarkMode((prev) => !prev)
    },
    enable: () => {
      console.log('enable')
      setDarkMode(true)
    },
    disable: () => {
      console.log('disable')
      setDarkMode(false)
    },
    set: (value) => {
      console.log('set')
      setDarkMode(value)
    },
  }
}
