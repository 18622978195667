import { observer } from 'mobx-react'

import { FC, useContext } from 'react'
import { Redirect } from 'wouter'
import { useTheme } from 'styled-components'
import { StoreContext } from '@components/App'
import { Spacer, SelectInput } from '@components/generic'
import {
  FilterRow,
  FixedFilter,
  Page,
  PageContainer,
  PageHeader,
  PageTitle,
  StyledPage,
  TitleRefreshWrapper,
} from '../TransactionListPage/TransactionsPage.styles'
import { DropdownWrapper } from '@/styles/generic.styles'
import { Menu } from '@components/Menu/Menu'
import { MobileHeader } from '@components/MobileHeader/MobileHeader'
import { SelectOption } from '@constants/SelectOption'
import { tagsOptions } from '@constants/settings'

export const SettingsPage: FC = observer(() => {
  const store = useContext(StoreContext)
  const { settingsPage: trans } = store.TranslationsState.translations
  const theme = useTheme()
  const { tagsSettings } = store.Settings

  const tagsOptionsList = tagsOptions.map((item) => ({ value: item.value, label: String(trans[item.value]) }))
  const tagsSettingsIndex = tagsOptionsList.findIndex((item) => item.value === tagsSettings)

  const { isLogged, isAuthorized } = store.Auth

  const onTagSettingsChange = (e: SelectOption) => store.Settings.setTagsSettings(e.value)

  return (
    <StyledPage>
      <Page>
        <PageHeader>
          <TitleRefreshWrapper>
            <PageTitle>{trans.pageTitle}</PageTitle>
          </TitleRefreshWrapper>
        </PageHeader>
        <Spacer size={19} mobileSize={0}></Spacer>
        <PageContainer open>
          <FilterRow>
            <FixedFilter>
              <div>{trans.tagsSettings}</div>
              <Spacer size={5} />
              <DropdownWrapper>
                <SelectInput
                  options={tagsOptionsList}
                  value={tagsOptionsList[tagsSettingsIndex]}
                  menuPlacement={'bottom'}
                  onChange={onTagSettingsChange}
                  {...theme.selectInput}
                />
              </DropdownWrapper>
            </FixedFilter>
          </FilterRow>
        </PageContainer>
      </Page>
      <MobileHeader />
      <Menu />

      {isLogged && !isAuthorized ? (
        <Redirect to={`/unauthorized?title=${trans.notAuthorized}&description=${trans.notAuthorizedMessage}`} />
      ) : (
        <></>
      )}
      {!isLogged ? <Redirect to={'/login'} /> : <></>}
    </StyledPage>
  )
})
