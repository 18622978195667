import { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Animatable } from '@styles/generic.styles'

export const StyledSettings = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 50%;
  padding: 4px;
  color: ${({ theme }) => theme.colors.secondary2};
  &:hover {
    border-radius: 8px;
  }

  ${Animatable}
`

export const SettingsIcon: FC<PropsWithChildren> = () => {
  return (
    <StyledSettings>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        fill="currentColor"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        transform="rotate(90)"
      >
        <g id="Other">
          <g>
            <g>
              <path
                d="M3,15c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S4.654,15,3,15z M3,10c-1.103,0-2,0.897-2,2s0.897,2,2,2s2-0.897,2-2
				S4.103,10,3,10z"
              />
            </g>
            <g>
              <path
                d="M12,15c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S13.654,15,12,15z M12,10c-1.103,0-2,0.897-2,2s0.897,2,2,2
				s2-0.897,2-2S13.103,10,12,10z"
              />
            </g>
            <g>
              <path
                d="M21,15c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3S22.654,15,21,15z M21,10c-1.103,0-2,0.897-2,2s0.897,2,2,2
				s2-0.897,2-2S22.103,10,21,10z"
              />
            </g>
          </g>
        </g>
      </svg>
    </StyledSettings>
  )
}
