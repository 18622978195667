import { observer } from 'mobx-react'
import { FC, useCallback, useContext } from 'react'
import { Button } from '@components/generic/Button/Button'
import { StoreContext } from '@components/App'
import { useSearch } from 'wouter'
import { Logo } from '@components/generic/Logo/Logo'
import { Spacer } from '@components/generic/Spacer/Spacer'
import { Subtitle } from '@components/generic/Subtitle/Subtitle'
import { Title } from '@components/generic/Title/Title'
import {
  Aligner,
  Container,
  LeftSide,
  PositionedIllustration,
  PositionedLogo,
  RightSide,
  TransparentContainer,
} from './ErrorPage.styles'
import { ErrorIllustration } from './ErrorIllustration/ErrorIllustration'
import { useTheme } from 'styled-components'

export const ErrorPage: FC = observer(() => {
  const store = useContext(StoreContext)
  const { errorPage: trans } = store.TranslationsState.translations
  const theme = useTheme()

  const searchParamsRaw = useSearch()
  const searchParams = new URLSearchParams(searchParamsRaw)

  const title = searchParams.get('title')
  const description = searchParams.get('description')

  const handleLogout = useCallback(() => {
    store.Auth.setIsLogged(false)
    store.Auth.setShouldLogout(true)
    store.Auth.setCurrentUserId('')
    window.location.href = '/login'
  }, [])

  return (
    <Container>
      <LeftSide>
        <PositionedLogo>
          <Logo></Logo>
        </PositionedLogo>
        <PositionedIllustration>
          <ErrorIllustration></ErrorIllustration>
        </PositionedIllustration>
      </LeftSide>
      <RightSide>
        <Aligner>
          <TransparentContainer>
            <Title>{title || trans.somethingWentWrong}</Title>
            <Spacer size={14}></Spacer>
            <Subtitle>{description}</Subtitle>
            <Spacer size={50} mobileSize={25}></Spacer>
            <Button {...theme.buttons.commonButton} onClick={handleLogout} fontSize="1.56rem">
              {trans.logOut}
            </Button>
            <Spacer size={25}></Spacer>
          </TransparentContainer>
        </Aligner>
      </RightSide>
    </Container>
  )
})
