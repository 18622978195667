import styled from 'styled-components'

interface ContainerProps {
  size: string
  color: string
  colorHover?: string
}

export const Container = styled.div<ContainerProps>`
  width: ${(props) => (props.size ? props.size : '18px')};
  height: ${(props) => (props.size ? props.size : '18px')};
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  svg {
    width: 100%;
    height: 100%;
  }

  svg .colorized {
    &--stroke {
      stroke: ${(props) => (props.color ? props.color : props.color)};
    }

    &--fill {
      fill: ${(props) => (props.color ? props.color : props.color)};
    }
  }

  svg:hover .colorized {
    &--stroke {
      stroke: ${(props) => (props.colorHover ? props.colorHover : props.color)};
    }

    &--fill {
      fill: ${(props) => (props.colorHover ? props.colorHover : props.color)};
    }
  }
`
