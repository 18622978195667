import { MutableRefObject } from 'react'
import { Button } from '@components/generic/Button/Button'
import { useTheme } from 'styled-components'
import { ContextMenuContainer } from './ContextMenu.styles'

type ContextMenuProps = {
  value: string
  positionX: number
  positionY: number
  isToggled: boolean
  contextMenuRef: MutableRefObject<HTMLDivElement>
  buttonText: string
  onClose: () => void
}

export const ContextMenu = ({
  value,
  positionX,
  positionY,
  isToggled,
  contextMenuRef,
  onClose,
  buttonText,
}: ContextMenuProps) => {
  const theme = useTheme()

  return (
    <ContextMenuContainer
      style={{ top: `${positionY + 2}px`, left: `${positionX + 2}px` }}
      ref={contextMenuRef}
      className={`context-menu ${isToggled ? 'active' : ''}`}
    >
      <Button
        {...theme.buttons.closeModalButton}
        onClick={async (e: MouseEvent) => {
          e.stopPropagation()
          try {
            await navigator.clipboard.writeText(value)
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Failed to copy: ', err)
          }
          onClose()
        }}
      >
        {buttonText}
      </Button>
    </ContextMenuContainer>
  )
}
