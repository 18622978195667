import styled, { css } from 'styled-components'

interface ContainerProps {
  bgColor?: string
  padding?: string
  paddingMobile?: string
  borderRadius?: string
  minWidth?: string
  width?: string
  height?: string
  widthMobile?: string
  heightMobile?: string
  border?: string
  boxShadow?: string
  margin?: string
  marginMobile?: string
  color?: string
  bgColorHover?: string
  borderHover?: string
  colorHover?: string
  bgColorDisabled?: string
  borderDisabled?: string
  colorDisabled?: string
  fontSize?: string
  fontSizeMobile?: string
  fontWeight?: string
  justifyContent?: string
  textDecoration?: string
  cursor?: string
  cursorDisabled?: string
  animatable?: boolean
}

export const Container = styled.button<ContainerProps>`
  outline: none;
  padding: ${(props) => (props.padding ? props.padding : '10px 15px')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#2c60ff')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '8px')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  border: ${(props) => (props.border ? props.border : '1px solid #2c60ff')};
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : 'none')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  color: ${(props) => (props.color ? props.color : '#FFFFFF')};
  display: flex;
  color: ${(props) => (props.color ? props.color : '#FFFFFF')};
  cursor: ${(props) => (props.cursor ? props.cursor : 'pointer')};
  align-items: center;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'center')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '500')};
  text-decoration: ${(props) => (props.textDecoration ? props.textDecoration : 'none')};
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;

  .colorized--fill {
    fill: ${(props) => (props.color ? props.color : '#2c60ff')};
  }

  .colorized--stroke {
    stroke: ${(props) => (props.color ? props.color : '#0d319d')};
  }

  &:hover {
    color: ${(props) => (props.colorHover ? props.colorHover : '#ffffff')};
    background-color: ${(props) => (props.bgColorHover ? props.bgColorHover : '#0d319d')};
    border: ${(props) => (props.borderHover ? props.borderHover : '1px solid #0d319d')};

    .colorized--fill {
      fill: ${(props) => (props.colorHover ? props.colorHover : '#ffffff')};
    }

    .colorized--stroke {
      stroke: ${(props) => (props.bgColorHover ? props.bgColorHover : '#0d319d')};
    }
  }

  &:disabled,
  &[disabled] {
    background-color: ${(props) => (props.bgColorDisabled ? `${props.bgColorDisabled}` : '#9dafe5')};
    border: ${(props) => (props.borderDisabled ? `${props.borderDisabled}` : '1px solid #9dafe5')};
    color: ${(props) => (props.colorDisabled ? `${props.colorDisabled}` : '#dee6ff')};
    cursor: ${(props) => (props.cursorDisabled ? props.cursorDisabled : 'pointer')};

    .colorized--fill {
      fill: ${(props) => (props.colorDisabled ? props.colorDisabled : '#dee6ff')};
    }

    .colorized--stroke {
      stroke: ${(props) => (props.bgColorDisabled ? props.bgColorDisabled : '#9dafe5')};
    }
  }

  @media (max-width: 980px) {
    font-size: ${(props) => (props.fontSizeMobile ? props.fontSizeMobile : props.fontSize)};
    width: ${(props) => (props.widthMobile ? props.widthMobile : '100%')};
    height: ${(props) => (props.heightMobile ? props.heightMobile : 'auto')};
    padding: ${(props) => (props.paddingMobile ? props.paddingMobile : '10px 15px')};
    margin: ${(props) => (props.marginMobile ? props.marginMobile : '0')};
  }

  ${({ animatable }) =>
    animatable &&
    css`
      transition: all 0.5s ease-out;
    `}
`
