export function hideStyledComponentsWarns() {
  const originalConsoleWarn = console.warn

  console.warn = (message, ...args) => {
    if (typeof message === 'string' && message.includes('styled-components:')) {
      return
    }

    originalConsoleWarn(message, ...args)
  }

  const originalConsoleError = console.error

  console.error = (message, ...args) => {
    if (typeof message === 'string' && message.includes('Warning:')) {
      return
    }

    originalConsoleError(message, ...args)
  }
}
