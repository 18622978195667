import styled, { css } from 'styled-components'

export const StyledTable = styled.div`
  width: 100%;
`

export const TableContainer = styled.div`
  width: fit-content;
`

export const TableHeader = styled.div`
  display: flex;
  width: fit-content;
  height: 50px;
`

export const TableRow = styled.div<{
  disabled?: boolean
  notClickable?: boolean
}>`
  display: flex;
  width: fit-content;
  min-height: 60px;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.tertiary2};
        cursor: pointer;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.tertiary2};
      &:hover {
        background-color: none;
        cursor: not-allowed;
      }
    `}

  ${({ notClickable }) =>
    notClickable &&
    css`
      &:hover {
        cursor: default;
      }
    `}
`

export const TableHeaderCell = styled.div`
  position: relative;
  padding: 10px 14px 10px 10px;
  position: sticky;
  display: flex;
  align-items: center;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-of-type {
    padding-left: 20px;
  }

  @media (max-width: 820px) {
    font-size: 0.88rem;
    &:first-of-type {
      padding-left: 10px;
    }
  }
`

export const TableHeaderCellTitle = styled.div`
  display: flex;
  align-items: center;
`

export const TableRowCell = styled.div`
  border-top: 2px solid ${({ theme }) => theme.colors.tertiary2};
  display: flex;
  align-items: center;
  min-height: 60px;

  &:first-of-type {
    padding-left: 10px;
  }

  @media (max-width: 820px) {
    font-size: 0.88rem;
    &:first-of-type {
      padding-left: 0px;
    }
  }

  &.groupRow {
    background-color: ${({ theme }) => theme.colors.quinary};
  }
`

export const CellTextSelectionWrapper = styled.div`
  padding: 10px;
  cursor: text;
  position: relative;
  width: fit-content;
  max-width: 100%;

  & > div,
  & > pre {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
`

export const CellWrapper = styled.div`
  padding: 10px;
  cursor: pointer;
  position: relative;
  width: 100%;

  & > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
`

export const PillsCellWrapper = styled.div`
  cursor: pointer;
  position: relative;
  width: 100%;
`

export const Cell = styled.div`
  display: flex;
`

export const Resizer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: ${({ theme }) => theme.colors.tertiary2};
  cursor: col-resize;
  user-select: none;
  touch-action: none;

  &.isResizing {
    background: ${({ theme }) => theme.colors.secondary};
    opacity: 1;
  }

  @media (hover: hover) {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
`

export const PillContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  position: relative;
  overflow: visible !important;
`

export const Pill = styled.div`
  color: ${({ theme }) => theme.colors.secondary4};
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 6px 10px;
  border-radius: 16px;
  font-size: 0.8em;
`

export const PillsHover = styled.div`
  position: absolute;
  z-index: 1;
  top: 20px;
`
