import { css, styled } from 'styled-components'

export const Animatable = css`
  ${({ theme }) =>
    theme.animations.enabled &&
    css`
      transition: all ${({ theme }) => theme.animations.duration} ${({ theme }) => theme.animations.function};
    `}
`

export const TenantSelector = styled.div<{
  $hideOnMobile?: boolean
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  ${({ $hideOnMobile }) =>
    $hideOnMobile &&
    css`
      @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        display: none;
      }
    `}

  span {
    font-family: ${({ theme }) => theme.fonts.family};
    color: ${({ theme }) => theme.colors.senary};
    text-align: right;
    width: 100%;
  }
`

interface DropdownWrapperInterface {
  minHeight?: string
  height?: string
  indicatorPadding?: string
}

export const DropdownWrapper = styled.div<DropdownWrapperInterface>`
  height: ${(props) => (props.height ? props.height : '35px')};
  width: 100%;

  & > div {
    .select-input {
      &__value-container {
        padding: 0px 0px;
        flex-wrap: nowrap;
      }

      &__control {
        font-size: 14px;
        padding: 0px 4px 0px 16px;
        box-shadow: none;

        :hover {
          box-shadow: none;
        }

        &--is-focused {
          box-shadow: none;
        }

        &--is-disabled {
          box-shadow: none;
        }

        ${Animatable}
      }

      &__menu {
        font-size: 14px;
        margin-top: 0px;
        border-radius: 0px 0px 5px 5px;
        border: 1px solid ${({ theme }) => theme.colors.quinary};
      }
    }
  }
`

export const TenantDropdownWrapper = styled.div<DropdownWrapperInterface>`
  height: ${(props) => (props.height ? props.height : '35px')};
  width: 100%;

  & > div {
    .select-input {
      &__value-container {
        padding: 0px 0px;
        flex-wrap: nowrap;
      }

      &__control {
        font-size: 14px;
        padding: 0px 4px 0px 16px;
        box-shadow: none;
        border: 0;

        :hover {
          box-shadow: none;
        }

        &--is-focused {
          box-shadow: none;
        }

        &--is-disabled {
          box-shadow: none;
        }

        ${Animatable}
      }

      &__indicator-separator {
        display: none;
      }

      &__menu {
        font-size: 14px;
        margin-top: 0px;
        border-radius: 0px 0px 20px 20px;
        border: 2px solid ${({ theme }) => theme.colors.tertiary};
      }

      &__menu-list {
        border-radius: 0px 0px 17px 17px;
      }
    }
  }
`

export const SelectActiveTenantModalBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
  }

  span {
    display: none;
  }
`

export const InputLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
