import { FC } from 'react'
import { Route, Switch } from 'wouter'
import { observer } from 'mobx-react'

import { LoginPage } from '@/pages/LoginPage/LoginPage'
import { ErrorPage } from '@/pages/ErrorPage/ErrorPage'
import { CreateTransactionPage } from '@/pages/CreateTransactionPage/CreateTransactionPage'
import { SingleTransactionPage } from '@/pages/TransactionDetailsPage/SingleTransactionPage'
import { TransactionsPage } from '@/pages/TransactionListPage/TransactionsPage'
import { SettingsPage } from '@/pages/SettingsPage/SettingsPage'

export const ContentRouter: FC = observer(() => {
  return (
    <Switch>
      <Route path="/" component={LoginPage} />
      <Route path="/login" component={LoginPage} />

      <Route path="/error" component={ErrorPage} />
      <Route path="/unauthorized" component={ErrorPage} />
      <Route path="/unauthorized/:message" component={ErrorPage} />
      <Route path="/transactions" component={TransactionsPage} />
      <Route path="/transactions/:transactionId" component={SingleTransactionPage} />
      <Route path="/create-transaction" component={CreateTransactionPage} />
      <Route path="/settings" component={SettingsPage} />

      {/* Default route in a switch */}
      {/* <Route component={NotFoundPage} /> */}
    </Switch>
  )
})
